import { loadStripe } from "@stripe/stripe-js";

// Initialize Stripe with environment variable
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const PricingSection = () => {
  const handleCheckout = async (priceId) => {
    const stripe = await stripePromise;
    // Use environment variable for backend URL
    const backendUrl = process.env.REACT_APP_API_URL;
    try {
      // Create Checkout Session
      const response = await fetch(`${backendUrl}/create-checkout-session`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ priceId }),
      });

      if (!response.ok) {
        // Extract error message from response
        const errorData = await response.json();
        throw new Error(
          errorData.error || "Failed to create checkout session."
        );
      }

      const data = await response.json();
      console.log("Checkout session created:", data.url);

      // Redirect to Stripe Checkout
      window.location.href = data.url;
    } catch (error) {
      console.error("Error during checkout:", error);
      alert(`An error occurred: ${error.message}`);
    }
  };

  return (
    <>
      <section id="pricing" className="py-12">
        <div className="container mx-auto px-6">
          <h2 className="text-3xl font-semibold text-center text-gray-900 mb-8">
            Pricing
          </h2>
          {/* Pricing Plans */}
          <div className="flex flex-wrap justify-center -mx-4">
            {/* Monthly Plan */}
            <div className="w-full md:w-1/2 lg:w-1/3 px-4 mb-8">
              <div className="p-8 rounded border border-gray-200 bg-white text-center">
                <h3 className="text-2xl font-medium mb-4">Monthly</h3>
                <p className="text-gray-600 mb-6">
                  Get started with the essentials to efficiently manage your
                  schedule.
                </p>
                <p className="text-5xl font-bold text-gray-900 mb-6">
                  $5<span className="text-xl font-normal">/month</span>
                </p>
                <ul className="text-gray-600 mb-6 space-y-2">
                  <li>Unlimited access to scheduling features</li>
                  <li>Sync with Google Calendar</li>
                  <li>Basic support via email</li>
                </ul>
                <button
                  onClick={() =>
                    handleCheckout(process.env.REACT_APP_MONTHLY_SUBSCRIPTION)
                  }
                  className="px-6 py-3 rounded border border-gray-300 hover:bg-gray-100"
                >
                  Get Started
                </button>
              </div>
            </div>

            {/* Annual Plan */}
            <div className="w-full md:w-1/2 lg:w-1/3 px-4 mb-8">
              <div className="p-8 rounded border border-gray-200 bg-white text-center">
                <h3 className="text-2xl font-medium mb-4">Annual</h3>
                <p className="text-gray-600 mb-6">
                  Save more with the annual plan and enjoy uninterrupted
                  service.
                </p>
                <p className="text-5xl font-bold text-gray-900 mb-6">
                  $40<span className="text-xl font-normal">/year</span>
                </p>
                <ul className="text-gray-600 mb-6 space-y-2">
                  <li>Unlimited access to scheduling features</li>
                  <li>Sync with Google Calendar</li>
                  <li>Priority email support</li>
                  <li>Save 33% with the annual plan</li>
                </ul>
                <button
                  onClick={() =>
                    handleCheckout(process.env.REACT_APP_ANNUAL_SUBSCRIPTION)
                  }
                  className="px-6 py-3 rounded border border-gray-300 hover:bg-gray-100"
                >
                  Get Started
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PricingSection;
