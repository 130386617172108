// contexts/CameraContext.js
import React, { createContext, useContext, useEffect, useState } from "react";

const CameraContext = createContext();

export const useCamera = () => {
  return useContext(CameraContext);
};

export const CameraProvider = ({ children }) => {
  const [stream, setStream] = useState(null);
  const [error, setError] = useState(null);
  const [refCount, setRefCount] = useState(0);

  useEffect(() => {
    if (refCount === 0) {
      if (stream) {
        stream.getTracks().forEach((track) => track.stop());
        setStream(null);
      }
      return;
    }

    const startCamera = async () => {
      try {
        const mediaStream = await navigator.mediaDevices.getUserMedia({
          video: { facingMode: { ideal: "environment" } },
        });
        setStream(mediaStream);
      } catch (err) {
        try {
          const mediaStream = await navigator.mediaDevices.getUserMedia({
            video: true,
          });
          setStream(mediaStream);
        } catch (error) {
          setError("Unable to access camera.");
        }
      }
    };

    if (!stream) {
      startCamera();
    }

    return () => {
      if (stream) {
        stream.getTracks().forEach((track) => track.stop());
        setStream(null);
      }
    };
  }, [refCount, stream]);

  const incrementRef = () => setRefCount((prev) => prev + 1);
  const decrementRef = () => setRefCount((prev) => Math.max(prev - 1, 0));

  return (
    <CameraContext.Provider
      value={{ stream, error, incrementRef, decrementRef }}
    >
      {children}
    </CameraContext.Provider>
  );
};
