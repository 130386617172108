import React, { useState, useEffect } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import Success from "./Success";
import Cancel from "./Cancel";
import PrivacyPolicy from "./PrivacyPolicy";
import TermsOfService from "./TermsOfService";
import LoginPage from "./LandingPage";
import Dashboard from "./Dashboard";
import CameraComponent from "./CameraPage";
import { CameraProvider } from "./contexts/CameraContext";
import ProtectedRoute from "./components/ProtectedRoute";
import ErrorBoundary from "./components/ErrorBoundary";

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false); // Authentication state
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (isAuthenticated && location.pathname === "/") {
      navigate("/dashboard");
    }
  }, [isAuthenticated, navigate, location.pathname]);

  useEffect(() => {
    const checkSession = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/check-session`,
          {
            credentials: "include", // Ensures cookies are sent with the request
          }
        );

        if (!response.ok) {
          throw new Error(`Server error: ${response.statusText}`);
        }

        const data = await response.json();
        if (data.authenticated) {
          setIsAuthenticated(true); // Mark the user as authenticated
        } else {
          setIsAuthenticated(false);
        }
      } catch (error) {
        console.error("Error checking session:", error);
        setIsAuthenticated(false);
        // Optionally, set an error state to display an error message to the user
      }
    };

    checkSession();
  }, []);

  const handleGoogleLogin = () => {
    // Redirect to the backend /auth route for Google login
    console.log("Redirecting to:", process.env.REACT_APP_URL);

    window.location.href = `${process.env.REACT_APP_API_URL}/auth`;
  };

  const handleLogout = () => {
    fetch(`${process.env.REACT_APP_API_URL}/logout`, {
      method: "POST",
      credentials: "include",
    }).then(() => {
      setIsAuthenticated(false); // Update authentication state
      navigate("/"); // Navigate to home page
    });
  };

  return (
    <Routes>
      <Route
        path="/"
        element={<LoginPage handleGoogleLogin={handleGoogleLogin} />}
      />
      <Route
        path="/dashboard"
        element={
          <ProtectedRoute isAuthenticated={isAuthenticated}>
            <Dashboard handleLogout={handleLogout} />
          </ProtectedRoute>
        }
      />
      <Route
        path="/camera"
        element={
          <ProtectedRoute isAuthenticated={isAuthenticated}>
            <ErrorBoundary>
              <CameraComponent handleLogout={handleLogout} />
            </ErrorBoundary>
          </ProtectedRoute>
        }
      />
      <Route path="/privacy" element={<PrivacyPolicy />} />
      <Route path="/terms" element={<TermsOfService />} />
      <Route path="/success" element={<Success />} />
      <Route path="/cancel" element={<Cancel />} />
    </Routes>
  );
}

export default App;
