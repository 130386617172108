// Cancel.js
import React from "react";

const Cancel = () => {
  return (
    <div className="flex items-center justify-center h-screen">
      <div className="text-center">
        <h1 className="text-4xl font-bold mb-4">Subscription Canceled</h1>
        <p className="text-gray-700">
          Your subscription was not completed. Feel free to try again.
        </p>
      </div>
    </div>
  );
};

export default Cancel;
